.jaini-regular {
    font-family: "Jaini", system-ui;
    font-weight: 400;
    font-style: normal;
  }

.Lato {
    font-family: 'Lato', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
    font-family: 'Jaini', cursive;
    /* background: url('./ai-generated-8655276.jpg') no-repeat center center fixed;
    background-size:cover;
    background-attachment: fixed; */
    /* background-size:100% 100vh; */
    position: relative;
    margin: 0;
    min-height: 100vh;
    background-color: #000;
    color: #f0f0f0;
    overflow-y: auto;
    /* width: 100%; */
    padding-top: 6%;
}

body::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; /* Only cover the viewport height */
    background: url('./ai-generated-8655276.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; /* Keep it behind the content */
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container {
    margin: auto;
    width: 100%;
}

h1 {
    margin-bottom: 20px;
    /* color: #ff0000; */
    color: white;
    text-shadow: 2px 2px #000;
    font-stretch: expanded;
    font-size: 64px;
}

@media (max-width: 768px) {
    h1 {
      font-size: 44px;
    }
  }

form {
    display: flex;
    flex-direction: column;
}

label {
    margin-bottom: 10px;
    font-size: 20px;
}

textarea {
    width: 70%;
    padding: 10px;
    font-size: 30px;
    font-family: 'Jaini';
    margin: auto;
    background-color: #0000005c;
    color: #f0f0f0;
    outline: none;
    /* border: 1px solid #ff0000;
    border-radius: 5px; */
    border-radius: 16px;
    resize: none;
    min-height: 200px;
}

input {
    width: 25%;
    padding: 10px;
    font-size: 20px;
    font-family: 'Jaini';
    margin: 30px auto;
    background-color: #0000005c;
    color: #f0f0f0;
    outline: none;
    /* border: 1px solid #ff0000;
    border-radius: 5px; */
    border-radius: 16px;
}

@media (max-width: 768px) {
    input {
      width: 50%;
    }
  }

.button {
    padding: 10px;
    background-color: #C02A2A;
    color: white;
    cursor: pointer;
    font-size: 25px;
    border-radius: 16px;
    font-family: 'Jaini';
    border: none;
}

.submit-button {
    width: 20%;
    margin: 30px auto;
    padding: 10px;
}

@media (max-width: 768px) {
    .submit-button {
      width: 30%;
    }
  }

button:hover {
    background-color: #990000;
}

#message {
    margin-top: 10px;
    color: #ff0000;
    font-size: 18px;
}

.popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 10px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .popup {
      top: 30%;
    }
  }

.popup-content {
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.close-button {
    padding: 10px 20px;
}

.close-button:hover {
    background-color: #990000;
}

.content-padding {
    padding: 0% 3% 1% 3%;
}

a {
    color: #fff700;
    text-decoration: bold;
    font-weight: bold;
}

.footer {
    display: flex;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    gap: 16px;
    justify-content: center;
    padding-bottom: 8%;
    padding-left: 4%;
    padding-right: 4%;
}
@media (max-width: 768px) {
    .footer {
      flex-direction: column;
      gap: 8px;
    }
  }

.terms {
    overflow-y:visible;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form-content {
    
}
